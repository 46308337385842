<template>
  <div class="section2" id="section2">
    <div class="text-section-2">
      <h1>Informasi Pendaftaran</h1>
      <p>Informasi jadwal dan alur Penerimaan Peserta Didik Baru untuk SMP Al Bayan Islamic School tahun ajaran
        2023-2024</p>
    </div>
    <div class="carousel">
      <slider ref="slider" :options="options">
        <!-- slideritem wrapped package with the components you need -->
        <slideritem v-for="(item, index) in someList" :key="index" :style="item.style"><img :src="item.path"
            alt="Deskripsi Gambar" class="img-carousel" @click.prevent="showModal(index)" />
        </slideritem>
        <!-- Customizable loading -->
        <div slot="loading">loading...</div>
      </slider>
      <!-- <a-modal v-model="visible" title="Basic Modal" @ok="handleOk">
          <img :src="someList[modalImage].path" alt="Deskripsi Gambar" />
        </a-modal> -->
    </div>
    <div class="button">
      <a-button type="primary" @click.prevent="downloadImages">Download</a-button>
    </div>
  </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'
export default {
  components: {
    slider,
    slideritem,
  },
  data() {
    return {
      someList: [
        {
          html: 'slider1',
          style: {
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip1.webp'),
        },
        {
          html: 'slider2',
          style: {
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip2.webp'),
        },
        {
          html: 'slider3',
          style: {
            width: '700px',
            cursor: 'pointer',
          },
          path: require('@/assets/ip2.webp'),
        },
      ],
      options: {
        currentPage: 0,
        speed: 300,
        itemAnimation: true,
        centeredSlides: true,
        thresholdDistance: 100,
        thresholdTime: 300,
        loopedSlides: 2,
        slidesToScroll: 1,
        loop: true,
      },
      dataTable: [
        {
          key: '1',
          no: '1',
          jenisKeuangan: 'Uang Pembangunan',
          biaya: 'Rp. 5.000.000',
        },
        {
          key: '2',
          no: '2',
          jenisKeuangan: 'Pengembangan sarana dan prasarana',
          biaya: 'Rp. 2.000.000',
        },
        {
          key: '3',
          no: '3',
          jenisKeuangan: 'Kegiatan akademik',
          biaya: 'Rp. 1.000.000',
        },
        {
          key: '4',
          no: '4',
          jenisKeuangan: 'SPP Bulan Juli 2023',
          biaya: 'Rp. 750.000',
        },
        {
          key: '5',
          no: '5',
          jenisKeuangan: 'Kegiatan Kesiswaan Selama Setahun',
          biaya: 'Rp. 1.500.000',
        },
        {
          key: '6',
          no: '6',
          jenisKeuangan: 'Seragam Sekolah (1 Stel Yayasan, 1 Stel Batik, 1 Stel Olahraga, Jas Almamater, Badge dan Topi)',
          biaya: 'Rp. 1.200.000',
        },
        {
          key: '7',
          no: '7',
          jenisKeuangan: 'Sarana Kebutuhan Siswa (Asuransi, Buku Kendali Siswa, Kartu Pelajar, Map Raport, Map Ijazah)',
          biaya: 'Rp. 500.000',
        },
      ],
      visible: false,
      modalImage: 0,
    }
  },
  computed: {
    // ...mapState(['currentRoute']), // Anda dapat menggunakan Vuex untuk mengelola route aktif
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onChange(a, b, c) {
      console.log(a, b, c)
    },
    showModal(idx) {
      this.visible = true
      this.modalImage = idx
    },
    handleOk(e) {
      // console.log(e)
      this.visible = false
    },
    downloadImages() {
      // Daftar nama file gambar yang akan diunduh

      // Loop melalui setiap nama file gambar dan unduh
      this.someList.forEach((imageFile, index) => {
        const imageUrl = imageFile.path
        const link = document.createElement('a')
        link.href = imageUrl
        link.download = `Informarsi Selebaran ${index + 1}`
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  },
}
</script>
<style scoped>
.section2 {
  display: flex;
  flex-direction: column;
  height: 700px;
  margin-top: 70px;
  padding-top: 40px;
}

.text-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-section-2 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  text-align: center;
}

.text-section-2 p {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  /* 48px */
  /* width: 600px; */
}

.carousel {
  margin-top: 20px;
  height: 400px;
}

.slider-item {
  transform: scale(0.8);
  transition-timing-function: ease;
  transition-duration: 300ms;
  align-items: center;
}

.slider-item.slider-active {
  transform: scale(1.0);
  z-index: 999;
}

.slider-item.slider-active-copy {
  transform: scale(1.0);
  z-index: 999;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button .ant-btn-primary {
  width: 124px;
  height: 42px;
  margin-top: 20px;
}

.img-carousel {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .text-section-2 h1 {
    font-size: 18px;
  }

  .text-section-2 p {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 740px) {
  .carousel {
    /* margin-top: 20px;
    height: 200px; */
  }

  .img-carousel {
    object-fit: contain;
  }

  .section2 {
    margin-top: 20px;
    padding: 20px;
  }
}
</style>
